import React, { useRef, useEffect, useState } from "react";
import Footer from "../../components/footer/Footer";
import Navbar from "../../components/navbar/Navbar";
import "./registrationPage.css";
import { useLocation } from "react-router-dom";
import { FaWhatsapp } from "react-icons/fa";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// import emailjs from "emailjs-com";

import emailjs from "@emailjs/browser";
import ToTopBtn from "../../components/toTopBtn/ToTopBtn";

import Helpers from "../../components/helpers/Helpers";
import Gallery from "../../components/gallery/Gallery";
import SecondGallery from "../../components/secondGallery/SecondGallery";
import ThirdGallery from "../../components/thirdGallery/ThirdGallery";
import FourthGallery from "../../components/fourthGallery/FourthGallery";
import FifthGallery from "../../components/fifthGallery/FifthGallery";

const RegistrationPage = () => {
  const [thumbsSwiper, setThumbsSwiper] = useState();
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_f7ad82s",
        "template_iklfr1e",
        form.current,
        "mHLHpX5U6wZmhSfEM"
      )
      .then(
        (result) => {
          // alert(result.text);

          toast.success(result.text, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        },
        (error) => {
          // alert(error.text);

          toast.error(error.text, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      );

    e.target.reset();
  };
  return (
    <div className="maxWidthStyle ">
      <Navbar />
      <ToastContainer />

      <div>
        {/* <a
          aria-label="Chat on WhatsApp"
          href="https://wa.me/2347030916267"
          style={{ textDecoration: "none", cursor: "pointer" }}
          target="_blank"
        >
          <div className="whatsappStickbody">
            <div className="whatsappStickActive"></div>
            <FaWhatsapp />
          </div>
        </a> */}

        <ToTopBtn />

        <div className="regisPage-genFam">
          <div className="ourgallery-heading-text ">Our Recent Achievement</div>
          <FourthGallery />
          <div className="ourgallery-heading-text ">Our Gallery</div>
          <Gallery />
          <div className="ourgallery-heading-text ">Our Gallery</div>
          <SecondGallery />
          <div className="ourgallery-heading-text ">Our Gallery</div>
          <ThirdGallery />
          <div className="ourgallery-heading-text ">Our Code of Conduct</div>
          <FifthGallery />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default RegistrationPage;
