import React, { useState, useEffect } from "react";
import { FaInstagram } from "react-icons/fa";
import { FaFacebookSquare } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { FaWhatsapp } from "react-icons/fa";
// import VscTwitter from "react-icons/vsc";
import { FaTwitter } from "react-icons/fa";
import { FaAngleDown } from "react-icons/fa";
import "./navbar.css";
import utopiaClearLogo from "../../assets/icons/utopiaClearLogo.png";
import { NavLink, useLocation, Link, useNavigate } from "react-router-dom";
import Burger from "../mobileNav/Burger";
import Helpers from "../helpers/Helpers";

const Navbar = () => {
  useEffect(() => {
    const changeWidth = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", changeWidth);

    return () => {
      window.removeEventListener("resize", changeWidth);
    };
  }, []);

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const [switchSmallNav, setSwitchSmallNav] = useState(false);

  const switchHandler = () => {
    setSwitchSmallNav(true);
  };
  const location = useLocation();
  const navigate = useNavigate();

  const { pathname } = location;

  const splitLocation = pathname.split("/");

  const [show, setShow] = useState(false);
  const showDropdown = (e) => {
    setShow(!show);
  };
  const inSide = (e) => {
    setShow(true);
  };
  const hideDropdown = (e) => {
    setShow(false);
  };

  const greLink = () => {
    navigate("/");
    showDropdown();
  };
  const gmatLink = () => {
    navigate("/gmat_page");
    showDropdown();
  };
  const pteLink = () => {
    navigate("/pte_page");
    showDropdown();
  };
  const toeflLink = () => {
    navigate("/toefl_page");
    showDropdown();
  };
  const ieltsLink = () => {
    navigate("/ielts_page");
    showDropdown();
  };

  const satLink = () => {
    navigate("/sat_page");
    showDropdown();
  };

  return (
    <div className="maxWidthStyle">
      {screenWidth < 992 && (
        <div className="mobilenavbar-optMenuFam">
          <Link
            to="/"
            className="mobilenavbar-optMenulogo"
            style={{ textDecoration: "none" }}
          >
            <img src={Helpers.triumphant_logoC} />
          </Link>
          <div>
            <Burger />
          </div>
        </div>
      )}
      <div className="navbar-infoFamFlex">
        <div className="navbar-infoNumber">
          CALL US: 07030916267, 09020174506, 08091609312
        </div>
        <div className="navbar-infoIconsFam">
          <a
            aria-label="Instagram"
            href="https://www.instagram.com/triumphantjumakschools/"
            style={{ textDecoration: "none", cursor: "pointer" }}
            target="_blank"
          >
            <div className="navbar-infoIconEach">
              <FaInstagram />
            </div>
          </a>
          <a
            aria-label="Chat on WhatsApp"
            href="https://wa.me/2347030916267"
            style={{ textDecoration: "none", cursor: "pointer" }}
            target="_blank"
          >
            <div className="navbar-infoIconEach">
              <FaWhatsapp />
            </div>
          </a>
          {/* <a
            aria-label="twitter"
            // href="https://twitter.com/utopiaconsulthq"
            style={{ textDecoration: "none", cursor: "pointer" }}
            target="_blank"
          >
            <div className="navbar-infoIconEach">
              <FaTwitter />
            </div>
          </a> */}

          <div className="navbar-infoIconEach">
            <FaLinkedin />
          </div>

          <a
            aria-label="Facebook"
            href="https://web.facebook.com/people/Triumphant-jumak-Schools/100092976781906/"
            style={{ textDecoration: "none", cursor: "pointer" }}
            target="_blank"
          >
            <div className="navbar-infoIconEach">
              <FaFacebookSquare />
            </div>
          </a>
        </div>
      </div>

      {screenWidth > 992 && (
        <div className="navbar-optMenuFam">
          <Link
            to="/"
            className="navbar-optMenulogo"
            style={{ textDecoration: "none" }}
          >
            <img src={Helpers.triumphant_logoC} />
          </Link>

          <Link
            to="/"
            className={
              splitLocation[1] === "" ? "activeHeader" : "navbar-optMenuopt"
            }
            style={{ textDecoration: "none" }}
          >
            HOME
          </Link>
          <Link
            to="/about_us"
            className={
              splitLocation[1] === "about_us"
                ? "activeHeader"
                : "navbar-optMenuopt"
            }
            style={{ textDecoration: "none" }}
          >
            ABOUT US
          </Link>
          <div>
            <div
              // onMouseEnter={inSide}
              // onMouseLeave={hideDropdown}
              onClick={showDropdown}
              // show={show}
              className="navbar-optMenuopt navbar-optMenuoptExamm"
            >
              DEPARTMENTS <FaAngleDown className="navbar-optMenuoptExamArr" />
            </div>
            {show && (
              <div
                //   onMouseEnter={inSide}
                //   onMouseLeave={hideDropdown}
                className="navbar-ExamMenuFam"
              >
                <div
                  // onMouseEnter={showDropdown}
                  // onMouseLeave={hideDropdown}
                  // show={show}
                  onClick={() => {
                    greLink();
                  }}
                  className="navbar-ExamMenuOpt"
                >
                  <p>COLLEGE</p>
                </div>
                <div
                  onClick={() => {
                    greLink();
                  }}
                  // onMouseEnter={showDropdown}
                  // onMouseLeave={hideDropdown}
                  className="navbar-ExamMenuOpt"
                >
                  <p>PRIMARY</p>
                </div>
                <div
                  onClick={() => {
                    greLink();
                  }}
                  // onMouseEnter={showDropdown}
                  // onMouseLeave={hideDropdown}
                  className="navbar-ExamMenuOpt"
                >
                  <p>CRECHE/NURSERY</p>
                </div>
              </div>
            )}
          </div>
          <Link
            to="/registration"
            className={
              splitLocation[1] === "registration"
                ? "activeHeader"
                : "navbar-optMenuopt"
            }
            style={{ textDecoration: "none" }}
          >
            GALLERY
          </Link>
          {/* <Link
            to="/admission_page"
            className={
              splitLocation[1] === "admission_page"
                ? "activeHeader"
                : "navbar-optMenuopt"
            }
            style={{ textDecoration: "none" }}
          >
            BLOG
          </Link> */}
          {/* <Link
            to="/training"
            className={
              splitLocation[1] === "training"
                ? "activeHeader"
                : "navbar-optMenuopt"
            }
            style={{ textDecoration: "none" }}
          >
            TRAINING CENTER
          </Link> */}
          <Link
            to="/contact_page"
            className={
              splitLocation[1] === "contact_page"
                ? "activeHeader"
                : "navbar-optMenuopt"
            }
            style={{ textDecoration: "none" }}
          >
            CONTACT US
          </Link>
        </div>
      )}
    </div>
  );
};

export default Navbar;
