import React, { useEffect } from "react";
import Slider from "react-slick";

import { useLocation } from "react-router-dom";

import { FaAngleRight } from "react-icons/fa";
import { FaAngleLeft } from "react-icons/fa";

import { useAnimation, motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import Carousel from "nuka-carousel";

import "./thirdGallery.css";
import Helpers from "../helpers/Helpers";

import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from "react-image-gallery";

const images = [
  {
    original: Helpers.fruit11,
    thumbnail: Helpers.fruit11,
  },
  {
    original: Helpers.fruit2,
    thumbnail: Helpers.fruit2,
  },
  {
    original: Helpers.fruit3,
    thumbnail: Helpers.fruit3,
  },
  {
    original: Helpers.fruit4,
    thumbnail: Helpers.fruit4,
  },
  {
    original: Helpers.fruit5,
    thumbnail: Helpers.fruit5,
  },
  {
    original: Helpers.fruit6,
    thumbnail: Helpers.fruit6,
  },
  {
    original: Helpers.fruit7,
    thumbnail: Helpers.fruit7,
  },
  {
    original: Helpers.fruit8,
    thumbnail: Helpers.fruit8,
  },
  {
    original: Helpers.fruit9,
    thumbnail: Helpers.fruit9,
  },
  {
    original: Helpers.fruit10,
    thumbnail: Helpers.fruit10,
  },
  {
    original: Helpers.fruit11,
    thumbnail: Helpers.fruit11,
  },
];

const squareVariants = {
  visible: { opacity: 1, transition: { duration: 1 } },
  hidden: { opacity: 0 },
};

const squareVariantsY = {
  visible: { opacity: 1, y: 0, transition: { duration: 2 } },
  hidden: { opacity: 0, y: 100 },
};
const ThirdGallery = () => {
  const location = useLocation();
  const controls = useAnimation();
  const [ref, inView] = useInView();
  //  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);
  return (
    <motion.span
      ref={ref}
      animate={controls}
      initial="hidden"
      variants={squareVariants}
      className="thirdgallerymargin"
    >
      <div className="homepage-carouselGene">
        <ImageGallery items={images} />
      </div>
    </motion.span>
  );
};

export default ThirdGallery;
