import React, { useRef, useEffect } from "react";
import Footer from "../../components/footer/Footer";
import Navbar from "../../components/navbar/Navbar";
import { useLocation } from "react-router-dom";
import emailjs from "@emailjs/browser";
import { FaWhatsapp } from "react-icons/fa";

import { FaMapMarkerAlt, FaPhoneAlt, FaEnvelope } from "react-icons/fa";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "./contactPage.css";
import ToTopBtn from "../../components/toTopBtn/ToTopBtn";

const ContactPage = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_xcg2tgk",
        "template_ig0zbod",
        form.current,
        "m11CD39AJfK--LWd7"
      )
      .then(
        (result) => {
          // alert(result.text);

          toast.success(result.text, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        },
        (error) => {
          // alert(error.text);

          toast.error(error.text, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      );

    e.target.reset();
  };
  return (
    <div className="maxWidthStyle ">
      <Navbar />
      <ToastContainer />
      <div>
        {/* <a
          aria-label="Chat on WhatsApp"
          href="https://wa.me/2347030916267"
          style={{ textDecoration: "none", cursor: "pointer" }}
          target="_blank"
        >
          <div className="whatsappStickbody">
            <div className="whatsappStickActive"></div>
            <FaWhatsapp />
          </div>
        </a> */}

        <ToTopBtn />
        <div className="contactUs-gen">
          <div className="contactUs-Famd">
            <div className="contactUs-Icon">
              <FaMapMarkerAlt />
            </div>
            <div className="contactUs-Text">
              <div className="contact-headingAdd">SECONDARY:</div>
              43 Ademola Abogunrin Omolayo Bus Stop, Meiran, Lagos State.
            </div>
            <div className="contactUs-Text">
              <div className="contact-headingAdd">PRIMARY:</div>
              9, Adebisi Adedire Baale Bus Stop, Meiran, Lagos State.
            </div>
          </div>
          <div className="contactUs-Famd">
            <div className="contactUs-Icon">
              <FaPhoneAlt />
            </div>
            <div className="contactUs-Text">
              07030916267, 09020174506, 08091609312
            </div>
          </div>

          <div className="contactUs-Famd">
            <div className="contactUs-Icon">
              <FaEnvelope />
            </div>
            <div className="contactUs-Text">
              triumphantjumakschools@gmail.com
            </div>
          </div>
        </div>
        <div className="contactPage-genFam">
          <div className="contactUs-MessageT">Send Us A Message</div>
          {/* <div className="contactUs-fillFormHead">Send Us A Message</div> */}
          <div className="contactUs-fillFormGuide">
            {" "}
            Fields marked with an * are required
          </div>
          <form onSubmit={sendEmail} ref={form}>
            <div className="contactUs-genFam-inputField">
              <input
                type="text"
                placeholder=" "
                name="name"
                required
                // onChange={(e) => {
                //   setAuth({ ...auth, email: e.target.value });
                //   validateEmail(e);
                //   setEmailAd(e.target.value);
                // }}
              />
              <span>Name *</span>
            </div>

            <div className="contactUs-genFam-inputField">
              <input
                type="email"
                placeholder=" "
                name="email"
                required
                // onChange={(e) => {
                //   setAuth({ ...auth, email: e.target.value });
                //   validateEmail(e);
                //   setEmailAd(e.target.value);
                // }}
              />
              <span>Enter Email *</span>
            </div>

            <div className="contactUs-genFam-inputField">
              <input
                type="tel"
                placeholder=" "
                name="phone_no"
                required
                // onChange={(e) => {
                //   setAuth({ ...auth, email: e.target.value });
                //   validateEmail(e);
                //   setEmailAd(e.target.value);
                // }}
              />
              <span>Phone Number *</span>
            </div>

            <div className="inputFieldPersonalfc">
              {/* <input
              type="email"
              placeholder=" "
              name="email"
              required
              // onChange={(e) => {
              //   setAuth({ ...auth, email: e.target.value });
              //   validateEmail(e);
              //   setEmailAd(e.target.value);
              // }}
            /> */}

              <textarea
                rows={4}
                cols={22}
                maxLength={250}
                placeholder="Enter short message *"
                name="message"
                required
              />
            </div>

            <div className="contactUs-genFam-inputFieldSubmit">
              <input type="submit" />
            </div>
          </form>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ContactPage;
