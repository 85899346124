import React, { useEffect } from "react";
import Slider from "react-slick";

import { useLocation } from "react-router-dom";

import { FaAngleRight } from "react-icons/fa";
import { FaAngleLeft } from "react-icons/fa";

import { useAnimation, motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import Carousel from "nuka-carousel";

import "./fourthGallery.css";
import Helpers from "../helpers/Helpers";

import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from "react-image-gallery";

const images = [
  {
    original: Helpers.maths1,
    thumbnail: Helpers.maths1,
  },
  {
    original: Helpers.maths2,
    thumbnail: Helpers.maths2,
  },
  {
    original: Helpers.maths3,
    thumbnail: Helpers.maths3,
  },
  {
    original: Helpers.maths4,
    thumbnail: Helpers.maths4,
  },
  {
    original: Helpers.maths5,
    thumbnail: Helpers.maths5,
  },
  {
    original: Helpers.maths6,
    thumbnail: Helpers.maths6,
  },
  {
    original: Helpers.maths7,
    thumbnail: Helpers.maths7,
  },
  {
    original: Helpers.maths8,
    thumbnail: Helpers.maths8,
  },
  {
    original: Helpers.maths9,
    thumbnail: Helpers.maths9,
  },
  {
    original: Helpers.maths10,
    thumbnail: Helpers.maths10,
  },
  {
    original: Helpers.maths11,
    thumbnail: Helpers.maths11,
  },
  {
    original: Helpers.maths13,
    thumbnail: Helpers.maths13,
  },
  {
    original: Helpers.maths14,
    thumbnail: Helpers.maths14,
  },
  {
    original: Helpers.maths15,
    thumbnail: Helpers.maths15,
  },
  {
    original: Helpers.maths16,
    thumbnail: Helpers.maths16,
  },
  {
    original: Helpers.maths17,
    thumbnail: Helpers.maths17,
  },
  {
    original: Helpers.maths18,
    thumbnail: Helpers.maths18,
  },
  {
    original: Helpers.maths19,
    thumbnail: Helpers.maths19,
  },
  {
    original: Helpers.maths20,
    thumbnail: Helpers.maths20,
  },
  {
    original: Helpers.maths21,
    thumbnail: Helpers.maths21,
  },
];

const squareVariants = {
  visible: { opacity: 1, transition: { duration: 1 } },
  hidden: { opacity: 0 },
};

const squareVariantsY = {
  visible: { opacity: 1, y: 0, transition: { duration: 2 } },
  hidden: { opacity: 0, y: 100 },
};
const FourthGallery = () => {
  const location = useLocation();
  const controls = useAnimation();
  const [ref, inView] = useInView();
  //  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);
  return (
    <motion.span
      ref={ref}
      animate={controls}
      initial="hidden"
      variants={squareVariants}
      className="fourthGallerymargin"
    >
      <div className="homepage-carouselGene">
        <ImageGallery items={images} />
      </div>
    </motion.span>
  );
};

export default FourthGallery;
