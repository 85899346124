import React, { useEffect } from "react";
import "./adminSection.css";
import { Link } from "react-router-dom";

import { useAnimation, motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import Helpers from "../helpers/Helpers";
// import { useLocation } from "react-router-dom";

const squareVariantsX = {
  visible: { opacity: 1, y: 0, transition: { duration: 2 } },
  hidden: { opacity: 0, y: 100 },
};

const AdminSection = () => {
  const controls = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);
  return (
    <motion.div
      ref={ref}
      animate={controls}
      initial="hidden"
      variants={squareVariantsX}
      className="admissionProcess-FamGen"
    >
      <div className="admissionProcess-FamHead">
        {" "}
        <h2>About Us</h2>
      </div>
      <div className="admissionProcess-Famcont">
        <div className="about_childOne">
          <div className="about_headOne">
            Our Mission Is To Coach And Mentor Every Child Morally, Scripturaly
            And Engender Social Interaction Early In Their Career
          </div>
          <div className="about_bodyOne">
            British and National education standard are aligned in ourcuriculum,
            tailored to develop the total child and meet his specific challenge.
            Incompliance with global best practice, when skills and competence
            gaps are identified in a child, he is timely assigned to specialized
            tutors for assistance in resolving the observed specifics.
          </div>
          <Link
            to="/admission_page"
            style={{ textDecoration: "none" }}
            className="admissionProcess-FamBtn"
          >
            <h4>LEARN MORE</h4>
          </Link>
        </div>
        <div className="about_childTwo">
          <img src={Helpers.triumphant_flyer} />
        </div>
      </div>
    </motion.div>
  );
};

export default AdminSection;
