import React, { useEffect } from "react";
import Footer from "../../components/footer/Footer";
import Navbar from "../../components/navbar/Navbar";
import "./homepage.css";
import Carousel from "nuka-carousel";
import cambridge from "../../assets/images/cambridge.jpg";
import chicago from "../../assets/images/chicago.jpg";
import defaultq from "../../assets/images/defaultq.jpg";
import download from "../../assets/images/download.jpg";
import havard from "../../assets/images/havard.jpg";
import london from "../../assets/images/london.jpg";
import ExamSection from "../../components/examSection/ExamSection";
import AdminSection from "../../components/adminSection/AdminSection";
import { FaWhatsapp } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

import { FaAngleRight } from "react-icons/fa";
import { FaAngleLeft } from "react-icons/fa";

import { useAnimation, motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import ToTopBtn from "../../components/toTopBtn/ToTopBtn";
import Gallery from "../../components/gallery/Gallery";
import Helpers from "../../components/helpers/Helpers";
import Team from "../../components/team/Team";
// import { useLocation } from "react-router-dom";

const squareVariants = {
  visible: { opacity: 1, transition: { duration: 1 } },
  hidden: { opacity: 0 },
};

const squareVariantsY = {
  visible: { opacity: 1, y: 0, transition: { duration: 2 } },
  hidden: { opacity: 0, y: 100 },
};

const Homepage = () => {
  const location = useLocation();
  const controls = useAnimation();
  const [ref, inView] = useInView();
  //  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  return (
    <div className="maxWidthStyle ">
      <Navbar />
      <div>
        {/* <a
          aria-label="Chat on WhatsApp"
          href="https://wa.me/2347030916267"
          style={{ textDecoration: "none", cursor: "pointer" }}
          target="_blank"
        >
          <div className="whatsappStickbody">
            <div className="whatsappStickActive"></div>
            <FaWhatsapp />
          </div>
        </a> */}

        <ToTopBtn />

        <motion.span
          ref={ref}
          animate={controls}
          initial="hidden"
          variants={squareVariants}
        >
          <div className="homepage-carouselGene">
            <Carousel
              renderAnnounceSlideMessage={({ currentSlide, slideCount }) =>
                `Slide ${currentSlide + 1} of ${slideCount}`
              }
              autoplay={true}
              // autoplayInterval={0.1}
              // autoplayReverse={true}
              enableKeyboardControls={true}
              dragging={true}
              // pauseOnHover={false}
              // speed={1}
              swiping={true}
              // transitionMode={["scroll3d"]}
              // easing="easeInOutElastic"
              speed={500}
              // autoplayReverse={true}
              // infiniteScroll={true}
              autoplayInterval={2000}
              wrapAround={true}
              renderCenterLeftControls={({ previousSlide }) => (
                <button className="caro_btn" onClick={previousSlide}>
                  <FaAngleLeft />
                </button>
              )}
              renderCenterRightControls={({ nextSlide }) => (
                <button className="caro_btn" onClick={nextSlide}>
                  <FaAngleRight />
                </button>
              )}
            >
              <div className="homepage-slider">
                <div className="cloud_gen">
                  <img src={Helpers.cloud_up} />
                </div>
                <div className=" homepage-sliderbac">
                  <img src={Helpers.school2} />
                </div>
                <div className="homepage-sliderabso">
                  <div className="homepage-sliderabso1">
                    <h1>
                      Welcome to Triumphant <br />
                      <span>Jumak</span> <span>Schools</span>
                    </h1>
                  </div>
                  <div className="homepage-sliderabso2">
                    {" "}
                    <h3>
                      " The need for quality education in Nigeria can <br />{" "}
                      never be overemphasized. "
                    </h3>
                  </div>
                  {/* <Link
                    to="/registration"
                    style={{ textDecoration: "none" }}
                    className="homepage-sliderabsobtn"
                  >
                    REGISTER TODAY
                  </Link> */}
                </div>
              </div>

              <div className="homepage-slider">
                <div className="cloud_gen">
                  <img src={Helpers.cloud_up} />
                </div>
                <div className=" homepage-sliderbac">
                  <img src={Helpers.school23} />
                </div>
                <div className="homepage-sliderabso">
                  <div className="homepage-sliderabso1">
                    <h1>Conducive learning environment</h1>
                  </div>
                  <div className="homepage-sliderabso2">
                    <h3>
                      " To Provide a Safe And Conducive Learning Environment For
                      Children While Helping Them To Acquire
                      <br /> Problem Solving Skills Through Practical Education
                      That Will Enable Them To Become <br /> Responsible And
                      Functional Members Of The Family And Society At Large. "
                    </h3>
                  </div>
                  {/* <Link
                    to="/registration"
                    style={{ textDecoration: "none" }}
                    className="homepage-sliderabsobtn"
                  >
                    REGISTER TODAY
                  </Link> */}
                </div>
              </div>
              <div className="homepage-slider">
                <div className="cloud_gen">
                  <img src={Helpers.cloud_up} />
                </div>
                <div className=" homepage-sliderbac">
                  <img src={Helpers.maths18} />
                </div>
                <div className="homepage-sliderabso">
                  <div className="homepage-sliderabso1">
                    <h1>Building the total child</h1>
                  </div>
                  <div className="homepage-sliderabso2">
                    <h3>
                      " Appropriate methods in teaching geared towards
                      children’s
                      <br />
                      rapid comprehension and critical thinking <br />
                      skills are being adopted. "
                    </h3>
                  </div>
                  {/* <Link
                    to="/registration"
                    style={{ textDecoration: "none" }}
                    className="homepage-sliderabsobtn"
                  >
                    REGISTER TODAY
                  </Link> */}
                </div>
              </div>
              <div className="homepage-slider">
                <div className="cloud_gen">
                  <img src={Helpers.cloud_up} />
                </div>
                <div className=" homepage-sliderbac">
                  <img src={Helpers.cultural6} />
                </div>
                <div className="homepage-sliderabso">
                  <div className="homepage-sliderabso1">
                    <h1>Embracing Diversity</h1>
                  </div>
                  <div className="homepage-sliderabso2">
                    {" "}
                    <h3>
                      " Teaching Students About Cultures <br />
                      From Around the World "
                      <br />
                    </h3>
                  </div>
                  {/* <Link
                    to="/registration"
                    style={{ textDecoration: "none" }}
                    className="homepage-sliderabsobtn"
                  >
                    REGISTER TODAY
                  </Link> */}
                </div>
              </div>
              <div className="homepage-slider">
                <div className="cloud_gen">
                  <img src={Helpers.cloud_up} />
                </div>
                <div className=" homepage-sliderbac">
                  <img src={Helpers.lab} />
                </div>
                <div className="homepage-sliderabso">
                  <div className="homepage-sliderabso1">
                    <h1>Building the total child</h1>
                  </div>
                  <div className="homepage-sliderabso2">
                    {" "}
                    <h3>
                      " Appropriate methods in teaching geared towards
                      children’s
                      <br />
                      rapid comprehension and critical thinking <br />
                      skills are being adopted. "
                    </h3>
                  </div>
                  {/* <Link
                    to="/registration"
                    style={{ textDecoration: "none" }}
                    className="homepage-sliderabsobtn"
                  >
                    REGISTER TODAY
                  </Link> */}
                </div>
              </div>
              <div className="homepage-slider">
                <div className="cloud_gen">
                  <img src={Helpers.cloud_up} />
                </div>
                <div className=" homepage-sliderbac">
                  <img src={Helpers.cultural5} />
                </div>
                <div className="homepage-sliderabso">
                  <div className="homepage-sliderabso1">
                    <h1>Transformative Education</h1>
                  </div>
                  <div className="homepage-sliderabso2">
                    {" "}
                    <h3>
                      " We develop intellectual curiosity, <br /> critical
                      thinking, and ethical leadership in our students. "
                    </h3>
                  </div>
                  {/* <Link
                    to="/registration"
                    style={{ textDecoration: "none" }}
                    className="homepage-sliderabsobtn"
                  >
                    REGISTER TODAY
                  </Link> */}
                </div>
              </div>
              <div className="homepage-slider">
                <div className="cloud_gen">
                  <img src={Helpers.cloud_up} />
                </div>
                <div className=" homepage-sliderbac">
                  <img src={Helpers.radio} />
                </div>
                <div className="homepage-sliderabso">
                  <div className="homepage-sliderabso1">
                    <h1>
                      Experiential Learning <br /> Opportunities
                    </h1>
                  </div>
                  <div className="homepage-sliderabso2">
                    {" "}
                    <h3>
                      " We prepare our students to succeed in their chosen{" "}
                      <br />
                      careers and become engaged citizens <br />
                      who contribute to the betterment of society. "
                    </h3>
                  </div>
                  {/* <Link
                    to="/registration"
                    style={{ textDecoration: "none" }}
                    className="homepage-sliderabsobtn"
                  >
                    REGISTER TODAY
                  </Link> */}
                </div>
              </div>
              <div className="homepage-slider">
                <div className="cloud_gen">
                  <img src={Helpers.cloud_up} />
                </div>
                <div className=" homepage-sliderbac">
                  <img src={Helpers.redcross2} />
                </div>
                <div className="homepage-sliderabso">
                  <div className="homepage-sliderabso1">
                    <h1>Join the Fun</h1>
                  </div>
                  <div className="homepage-sliderabso2">
                    {" "}
                    <h3>
                      " Exploring the Exciting World <br /> Student Clubs and
                      Organizations. "
                    </h3>
                  </div>
                  {/* <Link
                    to="/registration"
                    style={{ textDecoration: "none" }}
                    className="homepage-sliderabsobtn"
                  >
                    REGISTER TODAY
                  </Link> */}
                </div>
              </div>
            </Carousel>

            {/* <Gallery /> */}
          </div>
        </motion.span>

        <div className="homepage-introFam">
          <div className="single_flexMission">
            <div className="homepage-introFamHead">
              {" "}
              <h3>Our Mission</h3>
            </div>

            <div className="homepage-introFamBody">
              {" "}
              <p>
                To provide a dynamic and functional education to pupils/student
                with the main purpose of solving everyday problem. Give the
                child leadership opportunity and roles to be a change agent.
              </p>
            </div>

            {/* <Link
              to="/about_us"
              style={{ textDecoration: "none" }}
              className="homepage-introFamBtn"
            >
              <h4>LEARN MORE </h4>
            </Link> */}
          </div>
          <div className="single_flexMission">
            <div className="homepage-introFamHead">
              {" "}
              <h3>Our Vision</h3>
            </div>

            <div className="homepage-introFamBody">
              {" "}
              <p>
                To raise a crop of children that will be morally, physically,
                and academically good and acceptable anywhere they go; and
                compete favourable in all aspects of child development with
                children from other school in Nigeria and beyond.
              </p>
            </div>
          </div>
          <div className="single_flexMission">
            <div className="homepage-introFamHead">
              {" "}
              <h3>Our Core Value</h3>
            </div>

            <div className="homepage-introFamBody">
              {" "}
              <p>
                Diligence, Integrity of purpose action with unbroken commitment
                to Godliness.
              </p>
            </div>

            {/* <Link
              to="/about_us"
              style={{ textDecoration: "none" }}
              className="homepage-introFamBtn"
            >
              <h4>LEARN MORE </h4>
            </Link> */}
          </div>
        </div>
      </div>

      <ExamSection />

      <AdminSection />
      <Team />

      <Footer />
    </div>
  );
};

export default Homepage;
