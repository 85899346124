import React, { useState } from "react";

import "./leftNav.css";
import { NavLink, useLocation, Link, useNavigate } from "react-router-dom";
import { FaAngleDown } from "react-icons/fa";

const LeftNav = ({ open, openLogoutBtn, closeLogoutBtn, openLogoutPage }) => {
  const location = useLocation();

  const { pathname } = location;
  const navigate = useNavigate();

  const splitLocation = pathname.split("/");

  const [show, setShow] = useState(false);
  const showDropdown = (e) => {
    setShow(!show);
  };
  const inSide = (e) => {
    setShow(true);
  };
  const hideDropdown = (e) => {
    setShow(false);
  };

  const greLink = () => {
    navigate("/gre_page");
    showDropdown();
  };
  const gmatLink = () => {
    navigate("/gmat_page");
    showDropdown();
  };
  const pteLink = () => {
    navigate("/pte_page");
    showDropdown();
  };
  const toeflLink = () => {
    navigate("/toefl_page");
    showDropdown();
  };
  const ieltsLink = () => {
    navigate("/ielts_page");
    showDropdown();
  };

  return (
    <>
      <div
        open={open}
        style={{
          transform: `${open ? "translateX(0)" : "translateX(100%)"}`,
          transition: "transform 0.3s ease-in-out",
        }}
        className="sidemenu-family-cont"
      >
        <div className="top-navbar-rel">
          <div className="cont-main">
            <Link
              to="/"
              className={
                splitLocation[1] === ""
                  ? "leftNavbar-opt-active"
                  : "leftNavbar-opt"
              }
              style={{ textDecoration: "none" }}
            >
              HOME
            </Link>

            <Link
              to="/about_us"
              className={
                splitLocation[1] === "about_us"
                  ? "leftNavbar-opt-active"
                  : "leftNavbar-opt"
              }
              style={{ textDecoration: "none" }}
            >
              ABOUT US
            </Link>
            <Link
              to="/registration"
              className={
                splitLocation[1] === "registration"
                  ? "leftNavbar-opt-active"
                  : "leftNavbar-opt"
              }
              style={{ textDecoration: "none" }}
            >
              GALLERY
            </Link>

            <div>
              <div
                // onMouseEnter={inSide}
                // onMouseLeave={hideDropdown}
                onClick={showDropdown}
                // show={show}
                className="leftNavbar-opt"
                style={{ display: "flex", flexDirection: "row" }}
              >
                DEPARTMENTS
                <div
                  className={
                    show === true ? "Faq-titleArrowRota" : "Faq-titleArrow "
                  }
                >
                  <FaAngleDown className="navbar-optMenuoptExamArr" />
                </div>
              </div>
              {show && (
                <div
                //   onMouseEnter={inSide}
                //   onMouseLeave={hideDropdown}
                >
                  <Link
                    to="/"
                    className={
                      splitLocation[1] === ""
                        ? "leftNavbar-opt-active"
                        : "leftNavbar-optExam"
                    }
                    style={{ textDecoration: "none" }}
                  >
                    {/* <div
                    // onMouseEnter={showDropdown}
                    // onMouseLeave={hideDropdown}
                    // show={show}
                    onClick={() => {
                      greLink();
                    }}
                    className="leftNavbar-opt"
                    style={{ background: "#f7acb0", color: "#000" }}
                  > */}
                    COLLEGE
                  </Link>

                  <Link
                    to="/"
                    className={
                      splitLocation[1] === ""
                        ? "leftNavbar-opt-active"
                        : "leftNavbar-optExam"
                    }
                    style={{ textDecoration: "none" }}
                  >
                    PRIMARY
                  </Link>
                  <Link
                    to="/"
                    className={
                      splitLocation[1] === ""
                        ? "leftNavbar-opt-active"
                        : "leftNavbar-optExam"
                    }
                    style={{ textDecoration: "none" }}
                  >
                    CRECHE/NURSERY
                  </Link>
                </div>
              )}
            </div>

            {/* <Link
              to="/training"
              className={
                splitLocation[1] === "training"
                  ? "leftNavbar-opt-active"
                  : "leftNavbar-opt"
              }
              style={{ textDecoration: "none" }}
            >
              TRAINING CENTER
            </Link> */}

            <Link
              to="/contact_page"
              className={
                splitLocation[1] === "contact_page"
                  ? "leftNavbar-opt-active"
                  : "leftNavbar-opt"
              }
              style={{ textDecoration: "none", marginBottom: "100px" }}
            >
              CONTACT US
            </Link>
            <div
              // to="/contact_page"
              // className={
              //   splitLocation[1] === "contact_page"
              //     ? "leftNavbar-opt-active"
              //     : "leftNavbar-opt"
              // }
              style={{ textDecoration: "none", paddingBottom: "300px" }}
            ></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LeftNav;
