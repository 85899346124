import React, { useEffect } from "react";
import Footer from "../../components/footer/Footer";
import Navbar from "../../components/navbar/Navbar";
import "./aboutUs.css";
import { useLocation } from "react-router-dom";
import { useAnimation, motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { FaWhatsapp } from "react-icons/fa";
import ToTopBtn from "../../components/toTopBtn/ToTopBtn";

// import { useLocation } from "react-router-dom";

const squareVariantsX = {
  visible: { opacity: 1, y: 0, transition: { duration: 2 } },
  hidden: { opacity: 0, y: 100 },
};

const AboutUs = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const controls = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);
  return (
    <div className="maxWidthStyle ">
      <Navbar />
      <div>
        {/* <a
          aria-label="Chat on WhatsApp"
          href="https://wa.me/2347030916267"
          style={{ textDecoration: "none", cursor: "pointer" }}
          target="_blank"
        >
          <div className="whatsappStickbody">
            <div className="whatsappStickActive"></div>
            <FaWhatsapp />
          </div>
        </a> */}
        <ToTopBtn />
        <motion.div
          ref={ref}
          animate={controls}
          initial="hidden"
          variants={squareVariantsX}
        >
          <div className="aboutUs-heading-text">
            About Triumphant Jumak Schools
          </div>
          <div className="aboutUs-heading-body">
            <p>
              We are a dedicated community of educators, students, parents, and
              staff who strive to provide a holistic and comprehensive
              educational experience for our students. Our school is committed
              to creating a safe, supportive, and inclusive environment where
              every student can thrive and achieve their full potential. We
              believe in fostering a culture of respect, responsibility, and
              excellence, and we encourage our students to become lifelong
              learners who are active and engaged members of their communities.
              Our experienced and qualified teachers are passionate about
              education and are dedicated to helping students develop their
              academic, social, and emotional skills. We offer a rigorous and
              challenging academic program that prepares our students for
              college and beyond. Our curriculum includes a wide range of
              subjects, including English, Math, Science, Social Studies, and
              World Languages, as well as electives in areas such as Fine Arts,
              Music, and Technology. Beyond the classroom, we offer a variety of
              extracurricular activities, including sports teams, clubs, and
              organizations, that allow our students to explore their interests
              and passions. We believe that extracurricular activities are an
              essential part of a well-rounded education and help students
              develop critical skills such as leadership, teamwork, and
              communication. At our school, we understand that education is not
              just about academics; it's also about building character and
              fostering social-emotional development. We offer a range of
              programs and services to support our students' social-emotional
              needs, including counseling services, mentorship programs, and
              community service opportunities. We are proud of our school
              community and the achievements of our students. Our graduates go
              on to attend some of the top colleges and universities in the
              country and are successful in a wide range of careers. We believe
              that our commitment to excellence, coupled with our supportive and
              inclusive environment, is what sets us apart and makes us a
              leading secondary school. Thank you for considering our school. We
              look forward to working with you to help your child achieve their
              full potential.
            </p>
          </div>
        </motion.div>
        {/* <div className="aboutUs-MissionText">
          <div className="aboutUs-MissionEach">
            <div className="aboutUs-MissionEachHead">
              <h3>Our Mission</h3>
            </div>
            <div className="aboutUs-MissionEachBody">
              <p>
                Utopia Educational Consult Ltd will continue to offer
                Professional and best GMAT, GRE, TOEFL, SAT and IELTS training
                in Ogun State as part of its mission. To succeed, you’ll need to
                analyze information from a variety of sources, and develop
                strategies and make decisions based on that information. It’s
                called Integrated Reasoning and it’s something you do every day.
                Beginning on June 5, 2012, the GMAT exam will measure your
                ability to problem solving in this area.
              </p>
            </div>
          </div>

          <div className="aboutUs-MissionEach">
            <div className="aboutUs-MissionEachHead">
              <h3>Our Vision</h3>
            </div>
            <div className="aboutUs-MissionEachBody">
              <p>
                Our vision is to train as many willing Nigerian students as
                possible to attain full scholarship (abroad) to America and
                Canada High Schools, Universities, etc through SAT I&II, IELTS,
                TOEFL, GRE, GMAT, etc.; to help minimize if not solve the huge
                challenges of qualitative education in Nigeria educational
                system-using experienced instructors with vast knowledge of the
                aforementioned examinations.
              </p>
            </div>
          </div>
        </div> */}

        <div className="homepage-introFam">
          <div className="single_flexMission">
            <div className="homepage-introFamHead">
              {" "}
              <h3>Our Mission</h3>
            </div>

            <div className="homepage-introFamBody">
              {" "}
              <p>
                To provide a dynamic and functional education to pupils/student
                with the main purpose of solving everyday problem. Give the
                child leadership opportunity and roles to be a change agent.
              </p>
            </div>

            {/* <Link
              to="/about_us"
              style={{ textDecoration: "none" }}
              className="homepage-introFamBtn"
            >
              <h4>LEARN MORE </h4>
            </Link> */}
          </div>
          <div className="single_flexMission">
            <div className="homepage-introFamHead">
              {" "}
              <h3>Our Vision</h3>
            </div>

            <div className="homepage-introFamBody">
              {" "}
              <p>
                To raise a crop of children that will be morally, physically,
                and academically good and acceptable anywhere they go; and
                compete favourable in all aspects of child development with
                children from other school in Nigeria and beyond.
              </p>
            </div>
          </div>
          <div className="single_flexMission">
            <div className="homepage-introFamHead">
              {" "}
              <h3>Our Core Value</h3>
            </div>

            <div className="homepage-introFamBody">
              {" "}
              <p>
                Diligence, Integrity of purpose action with unbroken commitment
                to Godliness.
              </p>
            </div>

            {/* <Link
              to="/about_us"
              style={{ textDecoration: "none" }}
              className="homepage-introFamBtn"
            >
              <h4>LEARN MORE </h4>
            </Link> */}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AboutUs;
