import cultural1 from "../../assets/images/cultural1.jpg";
import cultural2 from "../../assets/images/cultural2.jpg";
import cultural3 from "../../assets/images/cultural3.jpg";
import cultural4 from "../../assets/images/cultural4.jpg";
import cultural5 from "../../assets/images/cultural5.jpg";
import cultural6 from "../../assets/images/cultural6.jpg";
import cultural7 from "../../assets/images/cultural7.jpg";
import cultural9 from "../../assets/images/cultural9.jpg";
import cultural10 from "../../assets/images/cultural10.jpg";
import cultural11 from "../../assets/images/cultural11.jpg";
import cultural13 from "../../assets/images/cultural13.jpg";
import cultural15 from "../../assets/images/cultural15.jpg";
import cultural16 from "../../assets/images/cultural16.jpg";
import cultural17 from "../../assets/images/cultural17.jpg";
import cultural18 from "../../assets/images/cultural18.jpg";
import cultural217 from "../../assets/images/cultural217.jpg";
import fruit1 from "../../assets/images/fruit1.jpg";
import fruit2 from "../../assets/images/fruit2.jpg";
import fruit3 from "../../assets/images/fruit3.jpg";
import fruit4 from "../../assets/images/fruit4.jpg";
import fruit5 from "../../assets/images/fruit5.jpg";
import fruit6 from "../../assets/images/fruit6.jpg";
import fruit7 from "../../assets/images/fruit7.jpg";
import fruit8 from "../../assets/images/fruit8.jpg";
import fruit9 from "../../assets/images/fruit9.jpg";
import fruit10 from "../../assets/images/fruit10.jpg";
import fruit11 from "../../assets/images/fruit11.jpg";
import radio from "../../assets/images/radio.jpg";
import redcross1 from "../../assets/images/redcross1.jpg";
import redcross2 from "../../assets/images/redcross2.jpg";
import school_director from "../../assets/images/school_director.jpg";
import triumphant_flyer from "../../assets/images/triumphant_flyer.jpg";
import triumphant_logo from "../../assets/images/triumphant_logo.jpg";
import triumphant_logoC from "../../assets/images/triumphant_logoC.png";
import lab from "../../assets/images/lab.jpeg";
import computer1 from "../../assets/images/computer1.jpg";
import cloud_up from "../../assets/images/cloud_up.png";

import cloud_down from "../../assets/images/cloud_down.png";
import fruitprimary from "../../assets/images/fruitprimary.jpg";
import radiosec from "../../assets/images/radiosec.jpg";
import maths1 from "../../assets/images/conducts/maths1.jpg";
import maths2 from "../../assets/images/conducts/maths2.jpg";
import maths6 from "../../assets/images/conducts/maths6.jpg";
import maths3 from "../../assets/images/conducts/maths3.jpg";
import maths4 from "../../assets/images/conducts/maths4.jpg";
import maths5 from "../../assets/images/conducts/maths5.jpg";
import maths7 from "../../assets/images/conducts/maths7.jpg";
import maths8 from "../../assets/images/conducts/maths8.jpg";
import maths9 from "../../assets/images/conducts/maths9.jpg";
import maths10 from "../../assets/images/conducts/maths10.jpg";

import maths11 from "../../assets/images/conducts/maths11.jpg";
import maths12 from "../../assets/images/conducts/maths12.jpg";
import maths13 from "../../assets/images/conducts/maths13.jpg";
import maths14 from "../../assets/images/conducts/maths14.jpg";
import maths15 from "../../assets/images/conducts/maths15.jpg";
import maths16 from "../../assets/images/conducts/maths16.jpg";
import maths17 from "../../assets/images/conducts/maths17.jpg";
import maths18 from "../../assets/images/conducts/maths18.jpg";
import maths19 from "../../assets/images/conducts/maths19.jpg";
import maths20 from "../../assets/images/conducts/maths20.jpg";
import maths21 from "../../assets/images/conducts/maths21.jpg";
import exco1 from "../../assets/images/conducts/exco1.jpg";
import exco2 from "../../assets/images/conducts/exco2.jpg";
import girle1 from "../../assets/images/conducts/girle1.jpg";
import conduct1 from "../../assets/images/conducts/conduct1.jpg";
import conduct2 from "../../assets/images/conducts/conduct2.jpg";
import conduct3 from "../../assets/images/conducts/conduct3.jpg";
import conduct4 from "../../assets/images/conducts/conduct4.jpg";
import conduct5 from "../../assets/images/conducts/conduct5.jpg";
import conduct6 from "../../assets/images/conducts/conduct6.jpg";
import conduct7 from "../../assets/images/conducts/conduct7.jpg";
import conduct8 from "../../assets/images/conducts/conduct8.jpg";
import conduct9 from "../../assets/images/conducts/conduct9.jpg";
import school1 from "../../assets/images/conducts/school1.jpg";
import school2 from "../../assets/images/conducts/school2.jpg";
import school23 from "../../assets/images/conducts/school23.jpg";

export default {
  cultural1,
  cultural2,
  cultural3,
  cultural4,
  cultural5,
  cultural6,
  cultural7,
  cultural9,
  cultural10,
  cultural11,
  cultural13,
  cultural15,
  cultural16,
  cultural17,
  cultural18,
  cultural217,
  fruit1,
  fruit2,
  fruit3,
  fruit4,
  fruit5,
  fruit6,
  fruit7,
  fruit8,
  fruit9,
  fruit10,
  fruit11,
  fruitprimary,
  radio,
  redcross1,
  redcross2,
  radiosec,
  school_director,
  triumphant_flyer,
  triumphant_logo,
  triumphant_logoC,
  lab,
  computer1,
  cloud_up,
  cloud_down,
  maths1,
  maths2,
  maths3,
  maths4,
  maths5,
  maths6,
  maths7,
  maths8,
  maths9,
  maths10,
  maths11,
  maths12,
  maths13,
  maths14,
  maths15,
  maths16,
  maths17,
  maths18,
  maths19,
  maths20,
  maths21,
  exco1,
  exco2,
  girle1,
  conduct1,
  conduct2,
  conduct3,
  conduct4,
  conduct5,
  conduct6,
  conduct7,
  conduct8,
  conduct9,
  school2,
  school23,
  school1,
};
