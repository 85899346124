import React, { useEffect } from "react";
import "./examSection.css";
import { Link } from "react-router-dom";

import { useAnimation, motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import Helpers from "../helpers/Helpers";
import ServicesCard from "../servicesCard/ServicesCard";
// import { useLocation } from "react-router-dom";

const squareVariantsX = {
  visible: { opacity: 1, y: 0, transition: { duration: 2 } },
  hidden: { opacity: 0, y: 100 },
};

const ExamSection = () => {
  const controls = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);
  return (
    <div className="examProfContFamGenn">
      <div className="cloud_down">
        <img src={Helpers.cloud_down} />
      </div>
      <div className="examProfContHead">
        <h2>Departments</h2>
      </div>
      <motion.div
        ref={ref}
        animate={controls}
        initial="hidden"
        variants={squareVariantsX}
        className="examProfContFamm"
      >
        <div className="examProfContEach">
          <ServicesCard
            serviceCardImg={Helpers.school1}
            serviceHead="CRECHE & NURSERY"
            serviceText="TO DEVELOP THE TOTAL CHILD"
            addressText="9, Adebisi Adedire Baale Bus Stop, Meiran, Lagos State."
          />
        </div>
        <div className="examProfContEach">
          <ServicesCard
            serviceCardImg={Helpers.school1}
            serviceHead="PRIMARY"
            serviceText="UNIVERSAL BASIC EDUCATION"
            addressText="9, Adebisi Adedire Baale Bus Stop, Meiran, Lagos State."
          />
        </div>
        <div className="examProfContEach">
          <ServicesCard
            serviceCardImg={Helpers.school23}
            serviceHead="COLLEGE"
            serviceText="GOVERNMENT APPROVED INSTITUTION"
            addressText="43, Ademola Abogunrin Omolayo Bus Stop, Meiran, Lagos State."
          />
        </div>
      </motion.div>
    </div>
  );
};

export default ExamSection;
