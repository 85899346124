import React, { useEffect } from "react";

import "./ptePage.css";

import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from "react-image-gallery";
import Helpers from "../../components/helpers/Helpers";

const images = [
  {
    original: Helpers.conduct1,
    thumbnail: Helpers.conduct1,
  },
  {
    original: Helpers.conduct2,
    thumbnail: Helpers.conduct2,
  },
  {
    original: "https://picsum.photos/id/1019/1000/600/",
    thumbnail: "https://picsum.photos/id/1019/250/150/",
  },
];

const PtePage = () => {
  return (
    <div>
      <ImageGallery items={images} />
      <ImageGallery items={images} />
    </div>
  );
};

export default PtePage;
