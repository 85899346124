import React, { useEffect } from "react";
import Slider from "react-slick";

import { useLocation } from "react-router-dom";

import { FaAngleRight } from "react-icons/fa";
import { FaAngleLeft } from "react-icons/fa";

import { useAnimation, motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import Carousel from "nuka-carousel";
import Helpers from "../helpers/Helpers";
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from "react-image-gallery";
import "./gallery.css";

const squareVariants = {
  visible: { opacity: 1, transition: { duration: 1 } },
  hidden: { opacity: 0 },
};

const squareVariantsY = {
  visible: { opacity: 1, y: 0, transition: { duration: 2 } },
  hidden: { opacity: 0, y: 100 },
};

const images = [
  {
    original: Helpers.computer1,
    thumbnail: Helpers.computer1,
  },
  {
    original: Helpers.radiosec,
    thumbnail: Helpers.radiosec,
  },
  {
    original: Helpers.redcross1,
    thumbnail: Helpers.redcross1,
  },
  {
    original: Helpers.redcross2,
    thumbnail: Helpers.redcross2,
  },
  {
    original: Helpers.lab,
    thumbnail: Helpers.lab,
  },
];
const Gallery = () => {
  const location = useLocation();
  const controls = useAnimation();
  const [ref, inView] = useInView();
  //  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);
  return (
    <motion.span
      ref={ref}
      animate={controls}
      initial="hidden"
      variants={squareVariants}
      className="gallerymargiin"
    >
      <div className="homepage-carouselGene">
        <ImageGallery items={images} />
      </div>
    </motion.span>
  );
};
export default Gallery;
