import React, { useEffect } from "react";
import "./team.css";
import Helpers from "../helpers/Helpers";
import { Link } from "react-router-dom";

import { useAnimation, motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

const squareVariantsX = {
  visible: { opacity: 1, y: 0, transition: { duration: 2 } },
  hidden: { opacity: 0, y: 100 },
};

const Team = () => {
  const controls = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);
  return (
    <motion.div
      ref={ref}
      animate={controls}
      initial="hidden"
      variants={squareVariantsX}
      className="teamProcess-FamGen"
    >
      <div className="team_genFlex">
        <div className="team_genOne">
          <div className="team_genImg">
            <img src={Helpers.school_director} />
          </div>
          <div className="team_directHead">Mrs. Kafilat Olayiwola K</div>
          <div className="team_directPara">Director</div>
        </div>

        <div className="team_genTwo">
          Education is not just about imparting knowledge, but about preparing
          young minds to face the challenges of the world with confidence and
          resilience. As the director of this school, I am committed to
          providing our students with the best possible learning experience, one
          that fosters intellectual curiosity, critical thinking, and a love of
          lifelong learning. Together, let's empower the next generation to
          create a better world for all.
        </div>
      </div>
    </motion.div>
  );
};

export default Team;
